import React from "react";

export const Section2 = (props) => {
  return (
    <div style={{ backgroundColor:"#fbf7f2"}}>
     
          <img style={{ maxWidth: '100%', height: 'auto' }} src="../img/soulrepimage2.jpg" alt="image" />
        
    </div>
  );
};

export default Section2;
