import React from "react";

export const Services3 = (props) => {
    return (
        <div style={{ backgroundColor: "#fbf7f2" }} >
<div className="market"  style={{ display: 'flex', justifyContent:"space-evenly" }}>
<div style={{ width: '100%', padding: '2rem' }}>
<div style={{ backgroundColor: "#7f8b81", height: '8rem', width: "45%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "1rem", marginBottom: "1rem" }} >
<p style={{ fontSize: "2rem", color: "#fbf7f2" }}>PR & Media Relations</p>
                    </div>
                    <p style={{ fontWeight: "initial", color: "#15445b", fontSize: "2.25rem", textAlign: "center" }}>
                        Our PR experts elevate your brand's visibility
                        through effective media relations, press trips,
                        targeted media placements and strategic
                        partnerships with influencers and opinion leaders. </p>
                </div>
                <div style={{ width: '100%' }} className="text-center">
                <img style={{ width: '60%', height: 'auto', maxWidth: "20rem" }} src="../img/highres-09.png" alt="Globe" />
                </div>
            </div>
            <div className="market" style={{marginTop:20, display: 'flex', justifyContent:"space-evenly" }}>
            <div style={{ width: '100%' }} className="text-center">
                <img style={{ width: '60%', height: 'auto', maxWidth: "20rem" }} src="../img/highres-06.png" alt="Globe" />
                </div>
                <div style={{ width: '100%', padding: '2rem' }}>
                <div style={{ backgroundColor: "#7f8b81", height: '8rem', width: "45%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "1rem", marginBottom: "1rem" }} >
                    <p style={{ fontSize: "2rem", color: "#fbf7f2" }}>Consultancy</p>
                    </div>
                    <p style={{ fontWeight: "initial", color: "#15445b", fontSize: "2.25rem", textAlign: "center" }}> 
                        Harness our decades of luxury hospitality expertise
                        for tailored sales, marketing, and PR solutions. Our
                        services span from hotel openings to strategic
                        planning, encompassing market analysis, affiliations,
                        design, and social media. Supported by industry
                        partners, we guarantee exceptional offerings and
                        foster direct business growth. </p>
                </div>
            </div>
        </div>
    );
};