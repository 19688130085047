import React from "react";

export const Services1 = (props) => {
  return (
    <div style={{ backgroundColor: "#ebe7df" }} >
      <div className="market" style={{ display: 'flex', justifyContent: "space-evenly" }}>
        <div style={{ width: '100%', padding: '2rem' }}>
          <div style={{ backgroundColor: "#6a684c", height: '8rem', width: "45%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: '1rem', marginBottom: '1rem' }} >
            <p style={{ fontSize: '2rem', color: "#fbf7f2" }}>Studio & Digital Marketing</p>
          </div>
          <p style={{ fontWeight: "initial", color: "#727059", fontSize: '2.5rem', textAlign: "center" }}>
            From content creation to branding, our creative
            team delivers innovative solutions and keeps you
            ahead of the curve. Expect innovative digital
            marketing solutions including social media
            management, email marketing and SEO/SEM
            strategies </p>
        </div>
        <div className="text-center" style={{ width: '100%' }} >
          <img style={{ width: '60%', height: 'auto', maxWidth: '20rem' }} src="../img/thesoulrep13.webp" alt="Globe" />
        </div>
      </div>
      <div  className="market" style={{ display: 'flex', justifyContent: 'space-evenly' }} >
        <div style={{ width: '100%', padding:"2rem" }}  className="text-center">
          <img style={{ width: '60%', height: 'auto', maxWidth:"20rem" }} src="../img/thesoulrep14.webp" alt="Globe" />
        </div>
        <div style={{ width: '100%', padding: "2rem" }}>
          <div style={{ backgroundColor: "#6a684c", height: '8rem', width: "45%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "1rem", marginBottom: "1rem" }} >
            <p style={{ fontSize: "2rem", color: "#fbf7f2" }}>Market Research</p>
          </div>
          <p style={{ fontWeight: "initial", color: "#727059", fontSize: "2.5rem", textAlign:"center" }}>
            Gain insights into market trends, consumer
            behaviour and competitors so you can
            maximize partnerships and develop integrated
            marketing plans for business growth </p>
        </div>
      </div>
    </div>
  );
};