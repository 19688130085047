import React from "react";

export const Section4 = (props) => {
  return (
    <div style={{ backgroundColor:"#fbf7f2"}}>
     
          <img style={{ maxWidth: '100%', height: 'auto' }} src="../img/soulrepimage1.jpg" alt="image" />
        
    </div>
  );
};

export default Section4;
