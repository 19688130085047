import React from "react";

export const Services = (props) => {
  return (
    // <div id="services" >

    //   <div style={{ padding: 20 }}>
    //     <h2 className="font-bold text-2xl" style={{ color: "#7c522a", marginLeft: 20 }}>Our Services</h2>
    //   </div>

    //   <div style={{ width: '100%', padding: 40 }}>

    //     <p style={{ fontWeight: "-moz-initial", color: "#7c522a", fontSize: 30 }}>
    //       At The Soul Rep, we are committed to providing a comprehensive suite of services
    //       tailored to meet the distinct needs of our clients. Here's how we can assist you in
    //       enhancing your brand presence and achieving your business goals

    //     </p>
    //   </div>
    //   <div className="market" style={{ display: 'flex', justifyContent: 'space-evenly' }} >
    //     <div style={{ width: '100%', padding: 40 }}>
    //       <div style={{ backgroundColor: "#7f8b81", height: '20%', width: "45%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 30, marginBottom: 10 }} >
    //         <p style={{ fontSize: 30, color: "#fbf7f2" }}>Sales And Marketing</p>
    //       </div>
    //       <p style={{ fontWeight: "-moz-initial", color: "#15445b", fontSize: 25 }}>
    //         Our expert team provides strategic guidance to
    //         optimize your sales and marketing efforts, ensuring
    //         maximum ROI and audience reach </p>
    //     </div>
    //     <div style={{ width: '100%' }} className="text-center">
    //       <img style={{ width: '60%', height: '60%' }} src="../img/highres-03.png" alt="Globe" />
    //     </div>
    //   </div>
    //   <div className="market" style={{ display: 'flex', justifyContent: 'space-evenly' }} >
    //     <div style={{ width: '100%' }} className="text-center">
    //       <img style={{ width: '60%', height: '60%' }} src="../img/highres-04.png" alt="Globe" />
    //     </div>
    //     <div style={{ width: '100%', padding: 40 }}>
    //       <div style={{ backgroundColor: "#7f8b81", height: '25%', width: "45%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 30, marginBottom: 10 }} >
    //         <p style={{ fontSize: 30, color: "#fbf7f2" }}>Market Representations</p>
    //       </div>
    //       <p style={{ fontWeight: "-moz-initial", color: "#15445b", fontSize: 25 }}>
    //         We act as dedicated representatives in key
    //         markets, leveraging our extensive network and
    //         industry expertise to promote your brand and
    //         drive business growth </p>
    //     </div>


    //   </div>


    // </div>

   
    <div id="services">

      <div style={{ padding: "2rem" }}>
        <h2 className="font-bold text-2xl" style={{ color: "#7c522a", marginLeft: "2rem" }}>Our Services</h2>
      </div>

      <div style={{ padding: "2rem", maxWidth: "100%" }}>
        <p style={{ fontWeight: "initial", color: "#7c522a", fontSize: "2.5rem" }}>
          At The Soul Rep, we are committed to providing a comprehensive suite of services
          tailored to meet the distinct needs of our clients. Here's how we can assist you in
          enhancing your brand presence and achieving your business goals
        </p>
      </div>

      <div className="market"  style={{ display: 'flex', justifyContent:"space-evenly" }}>
        <div style={{ width: '100%', padding: '2rem' }}>
          <div style={{ backgroundColor: "#7f8b81", height: '8rem', width: "45%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "1rem", marginBottom: "1rem" }} >
            <p style={{ fontSize: "2rem", color: "#fbf7f2" }}>Sales And Marketing</p>
          </div>
          <p style={{ fontWeight: "initial", color: "#15445b", fontSize: "2.25rem", textAlign: "center" }}>
            Our expert team provides strategic guidance to
            optimize your sales and marketing efforts, ensuring
            maximum ROI and audience reach </p>
        </div>
        <div style={{ width: '100%' }} className="text-center">
          <img style={{ width: '60%', height: 'auto', maxWidth: "20rem" }} src="../img/highres-03.png" alt="Globe" />
        </div>
      </div>

      <div className="market" style={{marginTop:20, display: 'flex', justifyContent:"space-evenly" }}>
        <div style={{ width: '100%' }} className="text-center">
          <img style={{ width: '60%', height: 'auto', maxWidth: "20rem" }} src="../img/thesoulrep15.webp" alt="Globe" />
        </div>
        <div style={{ width: '100%', padding: '2rem' }}>
        <div style={{ backgroundColor: "#7f8b81", height: '8rem', width: "45%", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "1rem", marginBottom: "1rem" }} >
         
          <p style={{ fontSize: "2rem", color: "#fbf7f2" }}>Market Representations</p>
          </div>
          <p style={{ fontWeight: "initial", color: "#15445b", fontSize: "2.25rem", textAlign: "center" }}> 
            We act as dedicated representatives in key
            markets, leveraging our extensive network and
            industry expertise to promote your brand and
            drive business growth </p>
        </div>
      </div>

    </div>
  );
};


